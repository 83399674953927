import React from "react";

const cerezpolitikasi = () => {
  return (
    <div className="text-center p-14">
      <h1 className="font-bold text-4xl">ÇEREZ POLİTİKASI</h1>
      <br />
      <h1 className="font-bold text-2xl">Genel</h1>
      <br />
      <p>
        Kullanılmakta olan Internet tarayıcısı aracılığı ile Internet ağ
        sunucusu tarafından kullanıcıların cihazlarına gönderilen küçük veri
        dosyaları çerez olarak anılmakta olup, Internet siteleri bu çerezler
        vasıtası ile kullanıcıları tanımaktadır. Çerezlerin ömrü tarayıcı
        ayarlarına bağlı olarak farklılaşmaktadır. <br /> Bu çerezler, Şirket
        tarafından yönetilmekte olan sistemler aracılığıyla oluşturulmakla
        birlikte, aynı zamanda Şirket tarafından yetkilendirilen bazı hizmet
        sağlayıcılar kullanıcıların cihazlarına benzeri teknolojiler
        yerleştirerek IP adresi, benzersiz tanımlayıcı ve cihaz tanımlayıcı
        bilgileri edinebilmektedir. Ayrıca, Şirket sistemlerinde bulunan üçüncü
        taraflara ait linkler, bu üçüncü taraflara ait gizlilik politikalarına
        tabi olmakla birlikte, gizlilik uygulamalarına ait sorumluluk Şirket’e
        ait olmamaktadır ve bu bağlamda ilgili link kapsamındaki site ziyaret
        edildiğinde siteye ait gizlilik politikasının okunması önerilmektedir.
      </p>
      <br />
      <ol>
        <li>
          <h2 className="font-bold text-2xl">Çerez Türleri:</h2>
          <br />
          <p>
            Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezler,
            temel olarak 4 ana grupta toplanmaktadır:
          </p>
          <br />
        </li>
        <li>
          <h2 className="font-bold text-2xl">Oturum Çerezleri:</h2>
          <br />
          <p>
            Internet sayfaları arasında bilgi taşınması ve kullanıcı tarafından
            girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli
            özelliklerden faydalanmaya olanak sağlayan çerezlerdir ve Şirket,
            Internet sitesine ait fonksiyonların düzgün bir şekilde
            işleyebilmesi için gereklidir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">Performans Çerezleri:</h2>
          <br />
          <p>Performans Çerezleri:</p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">Fonksiyonel Çerezler:</h2>
          <br />
          <p>
            Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan
            seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve Şirket
            Internet sitesi kapsamında kullanıcılara gelişmiş Internet
            özellikleri sağlanmasını hedeflemektedir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">
            Reklam Ve Üçüncü Taraf Çerezleri:
          </h2>
          <br />
          <p>
            Üçüncü parti tedarikçilere ait çerezlerdir ve Şirket Internet
            sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin
            yapılmasına olanak sağlamaktadır.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">Çerezlerin Kullanım Amaçları</h2>
          <br />
          <p>
            Şirketimiz tarafından kullanılmakta olan çerezlere ait kullanım
            amaçları aşağıdaki gibidir:
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">
            Operasyonel amaçlı kullanımlar:
          </h2>
          <br />
          <p>
            Şirketimiz, sistemlerinin idaresi ve güvenliğinin sağlanması
            amacıyla, bu sitedeki fonksiyonlardan yararlanmayı sağlayan veyahut
            düzensiz davranışları tespit eden çerezler kullanabilmektedir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">
            İşlevselliğe yönelik kullanımlar:
          </h2>
          <br />
          <p>
            Web sitemizi veya mobil uygulamamızı kullanarak ya da kayıt olarak
            bu Gizlilik Politikası’nı okuduğunuzu, anladığınızı ve kabul
            ettiğinizi beyan etmiş olursunuz. Web sitemizde yapılan
            değişiklikler yayınlandıktan sonra siteyi kullanmaya devam etmeniz,
            bu değişiklikleri kabul ettiğiniz anlamına gelir. Web sitemizi veya
            mobil uygulamamızı kullanarak ya da kayıt olarak bu Gizlilik
            Politikası’nı okuduğunuzu, anladığınızı ve kabul ettiğinizi beyan
            etmiş olursunuz.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">
            Performansa yönelik kullanımlar:
          </h2>
          <br />
          <p>
            Şirketimiz, sistemlerinin performansının artırılması ve ölçülmesi
            amacıyla, gönderilen iletilerle olan etkileşimi ve kullanıcı
            davranışlarını değerlendiren ve analiz eden çerezler
            kullanabilmektedir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">Reklam amaçlı kullanımlar:</h2>
          <br />
          <p>
            Şirketimiz, kendine veya üçüncü taraflara ait sistemlerin üzerinden
            kullanıcıların ilgi alanları kapsamında reklam ve benzeri
            içeriklerin iletilmesi amacıyla, bu reklamların etkinliğini ölçen
            veya tıklanma durumunu analiz eden çerezler kullanabilmektedir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">Çerezleri Devre Dışı Bırakmak</h2>
          <br />
          <p>
            Çerez kullanımı birçok tarayıcıda önceden tanımlı bir şekilde seçili
            olarak bulunmaktadır ve kullanıcılar bu seçim durumunu tarayıcı
            ayarlarından değiştirebilmekte ve dolayısıyla mevcut çerezleri silip
            ileriki çerez kullanımlarını da reddedebilmektedir; nitekim çerez
            kullanımının iptal edilmesi halinde, Şirket sistemlerindeki bir
            takım özelliklerden faydalanılamaması söz konusu olabilmektedir.
            <br />
            Çerez kullanım seçiminin değiştirilmesine ait yöntem, tarayıcı
            tipine bağlı olarak değişmekte olup, ilgili hizmet sağlayıcıdan
            dilendiği zaman öğrenilebilmektedir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">
            Web Sitesinde Bulunan Bilgi ve Materyal
          </h2>
          <br />
          <p>
            Şirketimiz web sitesinde bulunan bilgi, materyal ve bunların
            düzenlenmesi konusundaki telif hakları yine Şirketimize aittir. Web
            sitemizde yer alan ve üçüncü şahıslara ait materyaller dışında kalan
            bilgi ve materyale dair tüm telif hakları, tescilli marka, patent,
            fikri ve sair mülkiyet hakları Şirketmizde saklıdır.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default cerezpolitikasi;
