import React from "react";

const acikriza = () => {
  return (
    <div className="text-center p-14">
      <h1 className="font-bold text-4xl">
        KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AÇIK RIZA FORMU
      </h1>
      <br />
      <p>
        Jojo Tatil Turizm Seyahat Acenta Organizasyon Reklam Pazarlama İnşaat
        Taahhüt Ticaret Limited Şirketi (Jojo) tarafından sunulan/sunulacak ürün
        ve hizmetler amacıyla edindiği kişisel verilerimin; müşteri
        memnuniyetinin planlanması ve icrası, müşteri ilişkilerinin yönetimi,
        yeni ürün/hizmetlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına
        göre özelleştirilerek tarafıma önerilmesi, özel satış ve pazarlama
        faaliyetlerinin planlanması ve gerçekleştirilmesi, hacim arttırmaya
        yönelik tanıtım ve reklam faaliyetlerinin yürütülmesi, acente ve
        sağlayıcı sıfatıyla tarafıma sunulabilecek ürün/hizmetlerde kullanılması
        ve en yüksek faydanın elde edilmesi için ilgili süreçlerin planlanması
        ve icrası, analiz, etkin risk yönetimi ve raporlama amaçlarıyla
        işlenmesi ve bu amaçlar doğrultusunda kişisel verilerimi ilgili
        mevzuatın izin verdiği ölçüde Jojo'nun kendisine, açık rızam ile
        muvafakat ederim.
      </p>
      <br />
      <p>
        İlgili bilgilendirme kapsamında Jojo, mevzuat uyarınca veri güvenliğine
        ilişkin yükümlülüklerini tam ve eksiksiz olarak yerine getireceğini
        taahhüt etmekte olup, buna istinaden kişisel verilerimin kanuna aykırı
        olarak işlenmesi ve ele geçirilmesi sebebiyle zarara uğramam hâlinde,
        zararın giderilmesini talep etme hakkımı saklı tutarım.
      </p>
    </div>
  );
};

export default acikriza;
