import React from "react";

const gizliliksozlesmesi = () => {
  return (
    <div className="text-center p-14">
      <h1 className="font-bold text-4xl">GİZLİLİK POLİTİKASI</h1>
      <br />
      <p>
        Jojo Tatil Turizm Seyahat Acenta Organizasyon Reklam Pazarlama İnşaat
        Taahhüt Ticaret Limited Şirketi (“Şirket”), müşteri bilgilerinin
        gizliliğini ve güvenliğini en üst seviyede sağlamak amacıyla yürülükteki
        yasal mevzuatlar çerçevesinde gerekli tüm tedbirleri almaktadır. Bu
        gizlilik politikamız, Şirket'imizin web sitesi, mobil uygulamaları ve
        diğer dijital platformlarını kullanan müşterilerimiz için geçerlidir.
      </p>
      <br />
      <p>
        Web sitemize veya mobil uygulamamıza kayıt olan müşteriler, bu Gizlilik
        Politikası’nı elektronik ortamda kabul ederek aşağıdaki hususlara onay
        vermiş sayılırlar.
      </p>
      <br />
      <ol>
        <li>
          <h2 className="font-bold text-2xl">
            1. Kişisel Verilerin Toplanması ve Kullanım Amaçları
          </h2>
          <br />
          <p>
            {" "}
            Şirket, turizm hizmetlerini sunabilmek ve müşteri memnuniyetini
            sağlamak için aşağıdaki kanallar üzerinden kişisel verileri
            toplayabilir: Web sitesi, mobil uygulamalar, rezervasyon kanalları,
            e-posta veya telefon yoluyla; Seyahat acenteleri, otel rezervasyon
            sistemleri, sağlayıcılar, rehberlik hizmetleri gibi üçüncü taraf iş
            ortakları aracılığıyla. Toplanan veriler, aşağıdakilerle sınırlı
            olmamak kaydıyla, aşağıdaki ve benzer amaçlarla işlenebilir:
            Rezervasyon işlemleri ve seyahat planlamasının yürütülmesi; Müşteri
            hizmetleri ve destek taleplerinin yanıtlanması; Yasal
            zorunlulukların yerine getirilmesi; Pazarlama, reklam ve promosyon
            faaliyetlerinin yürütülmesi; Müşteri profili oluşturma ve müşteri
            sınıflandırması yaparak daha kişiselleştirilmiş hizmet sunulması;
            Dönemsel kampanyaların ve özel tekliflerin hazırlanması. Şirket,
            turizm hizmetlerini sunabilmek ve müşteri memnuniyetini sağlamak
            için aşağıdaki kanallar üzerinden kişisel verileri toplayabilir: Web
            sitesi, mobil uygulamalar, rezervasyon kanalları, e-posta veya
            telefon yoluyla; Seyahat acenteleri, otel rezervasyon sistemleri,
            rehberlik hizmetleri gibi üçüncü taraf iş ortakları aracılığıyla.
            Toplanan veriler, aşağıdaki amaçlarla işlenebilir: Rezervasyon
            işlemleri ve seyahat planlamasının yürütülmesi; Müşteri hizmetleri
            ve destek taleplerinin yanıtlanması; Yasal zorunlulukların yerine
            getirilmesi; Pazarlama, reklam ve promosyon faaliyetlerinin
            yürütülmesi.
          </p>
          <br />
        </li>
        <li>
          <h2 className="font-bold text-2xl">2. Verilerin Güvenliği</h2>
          <br />
          <p>
            Şirket, müşteri bilgilerinin güvenliğini sağlamak için teknik ve
            idari tedbirler almaktadır. Bu kapsamda: Yetkisiz erişimleri önlemek
            için güvenlik duvarları ve şifreleme teknolojileri kullanılır;
            Kişisel verilere erişim, sadece yetkili personel ile sınırlıdır;
            Veri ihlallerini önlemek amacıyla düzenli denetimler
            gerçekleştirilir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">3. Verilerin Paylaşımı</h2>
          <br />
          <p>
            Şirket, müşteri bilgilerinin gizliliğini korumak adına, verileri
            özellikle aşağıdaki durumlarda üçüncü taraflarla paylaşabilir:
            Müşteri onayı ile; Yasal yükümüllülükler gereği yetkili mercilerin
            talebi üzerine; Hizmet sağlayan iş ortakları (otel, havayolu
            şirketleri, sağlayıcılar, transfer hizmetleri vb.) ile hizmetin
            gerçekleştirilmesi için.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">4. Diğer Web Sitelerine Erişim</h2>
          <br />
          <p>
            Şirket, web sitesi, diğer web sitelerine bağlantılar içerebilir.
            Ancak, bağlantı verilen sitelerin gizlilik ve güvenlik
            uygulamalarından Şirket sorumlu değildir. Bu sitelere erişimden
            kaynaklanabilecek zararlar, kullanıcının kendi sorumluluğundadır.
            Web sitesi içeriklerinde yapılabilecek değişiklikler, yayınlandığı
            tarihten itibaren geçerli olacaktır. Kullanıcılar, web sitesini
            kullanmaya devam ederek bu değişiklikleri kabul etmiş sayılır.
            Şirket web sitesi, diğer web sitelerine bağlantılar içerebilir.
            Ancak, bağlantı verilen sitelerin gizlilik ve güvenlik
            uygulamalarından Şirket sorumlu değildir. Bu sitelere erişimden
            kaynaklanabilecek zararlar, kullanıcının kendi sorumluluğundadır.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">
            5. Veri Koruma Standartları ve Destek Hizmetleri
          </h2>
          <br />
          <p>
            Şirket, destek hizmeti sağlayan üçüncü tarafların gizlilik ve
            güvenlik standartlarına uygun hareket etmesini temin etmektedir.
            Veri işleme faaliyetleri, Şirketimizle akdedilen sözleşmeler
            çerçevesinde, yürürlükteki mevzuata uygun olarak yürütülmektedir.
            Üyelik iptali talep edildiğinde, müşterinin sistemde kayıtlı tüm
            kişisel bilgileri, yasal zorunlulukların izin verdiği ölçüde,
            güvenli bir şekilde silinir veya anonim hale getirilir. Bu süreç,
            veri güvenliğini en üst düzeyde sağlamak için alınan önlemler
            kapsamında gerçekleştirilir. Şirket, destek hizmeti sağlayan üçüncü
            tarafların gizlilik ve güvenlik standartlarına uygun hareket
            etmesini temin etmektedir. Veri işleme faaliyetleri, Şirket'imizle
            akdedilen sözleşmeler çerçevesinde, yürülükteki mevzuata uygun
            olarak yürütülmektedir.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">6. Telif Hakları</h2>
          <br />
          <p>
            Şirket, web sitesinde yer alan tüm bilgi, materyal ve bunların
            düzenlenmesi konusundaki tüm telif hakları Şirket'e aittir. Web
            sitemizde yer alan üçüncü taraflara ait materyaller dışında kalan
            tüm içerik, Şirket'imizin izni olmadan kopyalanamaz, dağıtılamaz
            veya kullanılamaz.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">7. Politika Değişiklikleri</h2>
          <br />
          <p>
            Şirket, bu Gizlilik Politikası’nda gerekli gördüğü durumlarda
            değişiklik yapma hakkını saklı tutar. Yapılan değişiklikler, web
            sitesinde yayınlandığı tarihte yürülüe girer.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">8. Onay ve Kabul</h2>
          <br />
          <p>
            Web sitemizi veya mobil uygulamamızı kullanarak ya da kayıt olarak
            bu Gizlilik Politikası’nı okuduğunuzu, anladığınızı ve kabul
            ettiğinizi beyan etmiş olursunuz. Web sitemizde yapılan
            değişiklikler yayınlandıktan sonra siteyi kullanmaya devam etmeniz,
            bu değişiklikleri kabul ettiğiniz anlamına gelir. Web sitemizi veya
            mobil uygulamamızı kullanarak ya da kayıt olarak bu Gizlilik
            Politikası’nı okuduğunuzu, anladığınızı ve kabul ettiğinizi beyan
            etmiş olursunuz.
          </p>
        </li>
        <li>
          <br />
          <h2 className="font-bold text-2xl">9. İletişim</h2>
          <br />
          <p>
            Gizlilik Politikamız ile ilgili her türlü soru ve talepleriniz için
            bize aşağıdaki iletişim bilgileri aracılığıyla ulaşabilirsiniz: -
            Adres: [Şirket Adresi] - Telefon: [Telefon Numarası] - E-posta:
            [E-posta Adresi]
          </p>
        </li>
      </ol>
    </div>
  );
};

export default gizliliksozlesmesi;
