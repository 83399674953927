import React from "react";

const Busdetay = () => {
  return (
    <div>
      <div className="container my-12 mx-auto md:px-6">
        {/* Section: Design Block */}
        <section className="mb-10 m-10">
          <h2 className=" text-center font-heading mt-2 text-3xl leading-8 m-2 font-bold tracking-tight text-gray-900 sm:text-4xl">
            Yolculuk Varış Noktasından Fazlasıdır, Bizimle Keyfe Dönüşür!
          </h2>
          <p className="mt-4 text-center mb-9 max-w-2xl text-lg text-neutral-700 lg:mx-auto">
            Hayalinizdeki yolculuk artık bir tık uzağınızda! Modern
            otobüslerimizle, konforu, güvenliği ve keyfi bir arada sunuyoruz.
          </p>
          <div className="grid gap-6 lg:grid-cols-2">
            <div className="mb-6 md:mb-8 lg:mb-12 m-2">
              <p className="mb-4 font-bold text-xl text-gray-600">
                Bus Travel Nedir?
              </p>
              <p className="text-neutral-800  text-left">
                Otobüs seyahati, tatil planlarınızı baştan sona keyifli bir
                deneyime dönüştürmenin en rahat yollarından biridir. Doğanın
                güzelliklerini camdan izlerken, yol boyunca yeni şehirler,
                manzaralar ve hikayelerle karşılaşma şansı sunar. Tatilin sadece
                varış noktasında başlamadığını, yolculuğun kendisinin de
                unutulmaz anılarla dolu olabileceğini fark edersiniz. <br />{" "}
                Özellikle deniz kenarındaki tatil beldelerine, tarihi şehir
                merkezlerine veya doğayla iç içe köylere yapılan otobüs
                seyahatleri, hem ekonomik hem de pratik bir seçenektir. Konforlu
                koltuklar, Wi-Fi hizmeti ve ikramlar sayesinde yolculuk süresi
                adeta su gibi akıp gider.
              </p>
            </div>
            <div className="mb-6 md:mb-8 lg:mb-12 m-2">
              <p className="font-bold text-xl text-gray-900">
                Bazı Soruların Cevabı.
              </p>
              <div className="mx-auto mt-8 grid max-w-xl divide-y divide-neutral-200">
                <div className="py-5">
                  <details className="group">
                    <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                      <span>Otobüs biletimi nasıl satın alabilirim?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="group-open:animate-fadeIn mt-3 text-neutral-600">
                      Web sitemizden veya mobil uygulamamızdan kolayca otobüs
                      biletinizi satın alabilirsiniz.
                    </p>
                  </details>
                </div>
                <div className="py-5">
                  <details className="group">
                    <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                      <span>
                        {" "}
                        Biletimi iptal edebilir veya değiştirebilir miyim?
                      </span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="group-open:animate-fadeIn mt-3 text-neutral-600">
                      Evet, biletinizi sefer saatinden en az 24 saat önce iptal
                      edebilir veya tarih değişikliği yapabilirsiniz. Bu işlemi
                      hesabınıza giriş yaparak veya müşteri hizmetlerimizle
                      iletişime geçerek gerçekleştirebilirsiniz.
                    </p>
                  </details>
                </div>
                <div className="py-5">
                  <details className="group">
                    <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                      <span>Bagaj hakkım ne kadar?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="group-open:animate-fadeIn mt-3 text-neutral-600">
                      Her yolcunun iki adet büyük bagaj (30 kg’a kadar) ve bir
                      el bagajı taşıma hakkı bulunmaktadır. Ekstra bagaj için ek
                      ücret ödenebilir.
                    </p>
                  </details>
                </div>
                <div className="py-5">
                  <details className="group">
                    <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                      <span> Otobüsler duraklıyor mu?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="group-open:animate-fadeIn mt-3 text-neutral-600">
                      Evet, uzun mesafeli seferlerde belirli molalar verilerek
                      yolcuların dinlenmesi sağlanır.
                    </p>
                  </details>
                </div>
              </div>
            </div>
            ;
          </div>
        </section>
        {/* Section: Design Block */}
        <section className="mb-10 m-10">
          <div className="grid gap-6 lg:grid-cols-2">
            <div className="mb-6 md:mb-8 lg:mb-12 m-2">
              <p className="mb-4 font-bold text-xl text-gray-900">
                Neden Bizi Seçmelisiniz?
              </p>
              <ul className="w-full text-surface dark:text-black ">
                <li className="w-full border-b-[1px] border-gray-500 py-4">
                  <strong> Konforlu Koltuklar: </strong> Geniş koltuk
                  aralıkları.
                </li>
                <li className="w-full border-b-[1px] border-gray-500 py-4 ">
                  <strong> Wi-Fi ve Eğlence: </strong> Ücretsiz internet ve
                  ekranlar.
                </li>
                <li className="w-full border-b-[1px] border-gray-500 py-4 ">
                  <strong> Zamanında Varış: </strong> Güvenilir zamanlama.
                </li>{" "}
                <li className="w-full border-b-[1px] border-gray-500 py-4 ">
                  <strong> 7/24 Destek: </strong> Canlı müşteri hizmetleri.
                </li>
              </ul>
            </div>
            <div className="mb-6 md:mb-8 lg:mb-12 m-2">
              <p className="mb-4 font-bold text-xl text-gray-900">
                Yolculuğunuzda Güven ve Temizlik Önceliğimizdir!
              </p>
              <ul className="w-full text-surface dark:text-black ">
                <li className="w-full border-b-[1px] border-gray-500 py-4">
                  <strong> Düzenli Dezenfeksiyon: </strong> Tüm otobüslerimiz
                  her sefer öncesi profesyonel ekipler tarafından detaylı bir
                  şekilde dezenfekte edilir.
                </li>
                <li className="w-full border-b-[1px] border-gray-500 py-4 ">
                  <strong> Deneyimli Kaptanlar: </strong> Kaptanlarımız düzenli
                  olarak trafik güvenliği ve araç kullanımı konusunda eğitim
                  alır.
                </li>{" "}
                <li className="w-full border-b-[1px] border-gray-500 py-4 ">
                  <strong>Yolcu Sayısının Kontrolü: </strong> Her sefer için
                  belirli bir yolcu kapasitesi sınırı uygulanır.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Busdetay;
